import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const Newsletter = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("success");
    setMessage("Done!");

    setTimeout(() => {
      setStatus("");
      setMessage("");
    }, 1000);
  };

  const clearFields = () => {
    setEmail("");
  };


  const API_URL = process.env.REACT_APP_API_URL;
  console.log('API_URL: ', API_URL);

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col xxl={12}>
            <h4 style={{ marginBottom: 20, fontWeight: 'bold' }}>{t("boletinText")}</h4>
            <div style={{ justifyContent: 'center' }} className="new-email-bx">
              <a href={`${API_URL}/my-cv`} target="_blank" style={{}} className="btn_cv">{t("btn_cv_text")}</a>
            </div>
          </Col>
        </Row>
        <Row>
          {status === "sending" && <Alert>Sending...</Alert>}
          {status === "error" && <Alert variant="danger">{message}</Alert>}
          {status === "success" && (
            <Alert className="fade" variant="success">
              {message}
            </Alert>
          )}
        </Row>
      </div>
    </Col>
  );
};
