import React, { useState } from 'react';
import YouTube from 'react-youtube';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'bootstrap';

const YouTubeGrid = ({ urlVerMas = 'https://www.youtube.com/playlist?list=PLGE1nAVn_sghnLQUj9pKO1knYOKa6HUtn', text = '' }) => {
    const [videos] = useState([
        'cEuoAkUb-EI',
        'DKj8cESinnE',
        'rJ7qfIdF2-M',
        'quF_yJNpKPE'
    ]);

    const opts = {
        height: '300',  // Ajusta el tamaño según sea necesario
        width: '100%',  // Para que el video se ajuste al contenedor
        playerVars: {
            autoplay: 0,
        },
    };

    return (
        <Container>
            <Row>
                {videos.map((videoId, index) => (
                    <Col xs={12} sm={12} md={12} lg={6} key={index} className="mb-4">
                        <YouTube videoId={videoId} opts={opts} />
                    </Col>
                ))}
                {/* Botón "Ver más" */}
                <Col xs={12} className="text-center mt-4">
                    <a href={urlVerMas} target="_blank" style={{}} className="btn_cv">{text}</a>
                </Col>
            </Row>
        </Container>
    );
};

export default YouTubeGrid;
