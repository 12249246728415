import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter3.svg";
import meter3 from "../assets/img/meter2.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import { Row, Col, Tab, Nav, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { ProjectCard } from "./ProjectCard";
import { useState } from "react";

export const Skills = () => {
  const { t } = useTranslation();
  const skillsObject = t("skillsObject", { returnObjects: true });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const tecnoloigasArray = [
    {
      name: "PHP",
      imgUrl: require("../assets/tecnologias/php.png"),
    },
    {
      name: "React",
      imgUrl: require("../assets/tecnologias/react.png"),
    },
    {
      name: "React-Native",
      imgUrl: require("../assets/tecnologias/react_native.webp"),
    },
    {
      name: "JavaScript",
      imgUrl: require("../assets/tecnologias/javascript.png"),
    },
    {
      name: "Python",
      imgUrl: require("../assets/tecnologias/python.png"),
    },
    {
      name: "Firebase",
      imgUrl: require("../assets/tecnologias/firebase.webp"),
      needBackground: true,
    },
    {
      name: "Firestore",
      imgUrl: require("../assets/tecnologias/firestore.png"),
      needBackground: true,
    },
    {
      name: "Android",
      imgUrl: require("../assets/tecnologias/android.png"),
    },
    {
      name: "Apple",
      imgUrl: require("../assets/tecnologias/apple.png"),
    },
    {
      name: "Swift",
      imgUrl: require("../assets/tecnologias/swift.png"),
      needBackground: true,
    },
    {
      name: "Kotlin",
      imgUrl: require("../assets/tecnologias/kotlin.png"),
      needBackground: true,
    },
    {
      name: "Haskell",
      imgUrl: require("../assets/tecnologias/haskell.png"),
      needBackground: true,
    },
    {
      name: "Java",
      imgUrl: require("../assets/tecnologias/java.png"),
    },
    {
      name: "C",
      imgUrl: require("../assets/tecnologias/C.png"),
    },
    {
      name: "C++",
      imgUrl: require("../assets/tecnologias/c++.png"),
    },
    {
      name: "C#",
      imgUrl: require("../assets/tecnologias/c-sharp.png"),
    },
    {
      name: "GIT",
      imgUrl: require("../assets/tecnologias/git.png"),
      needBackground: true,
    },
    {
      name: "SQLServer",
      imgUrl: require("../assets/tecnologias/SQLServer.png"),
      needBackground: true,
    },
    {
      name: "PostgreSQL",
      imgUrl: require("../assets/tecnologias/postgresql.png"),
      needBackground: true,
    },
    {
      name: "MySQL",
      imgUrl: require("../assets/tecnologias/mysql.png"),
      needBackground: true,
    },
    {
      name: "Linux",
      imgUrl: require("../assets/tecnologias/linux.png"),
    },
    {
      name: "Windows",
      imgUrl: require("../assets/tecnologias/windows.png"),
    },
  ];

  const frameworksArray = [
    {
      name: "Laravel",
      imgUrl: require("../assets/tecnologias/laravel.png"),
    },
    {
      name: "CodeIgniter",
      imgUrl: require("../assets/tecnologias/codeigniter.png"),
    },
    {
      name: "Bootstrap",
      imgUrl: require("../assets/tecnologias/bootstrap.png"),
      needBackground: true,
    },
    {
      name: "JQuery",
      imgUrl: require("../assets/tecnologias/jquery.png"),
      needBackground: true,
    },
    {
      name: "Django",
      imgUrl: require("../assets/tecnologias/python.png"),
    },
    {
      name: "React",
      imgUrl: require("../assets/tecnologias/react.png"),
    },
    {
      name: "React-Native",
      imgUrl: require("../assets/tecnologias/react_native.webp"),
    },
    {
      name: "NetBeans",
      imgUrl: require("../assets/tecnologias/netbeans.png"),
    },
    {
      name: "Expo",
      imgUrl: require("../assets/tecnologias/expo.png"),
      needBackground: true,
    },
    {
      name: "Android Studio",
      imgUrl: require("../assets/tecnologias/android_studio.png"),
    },
    {
      name: "Xcode",
      imgUrl: require("../assets/tecnologias/xcode.png"),
    },
    {
      name: "OpenCV",
      imgUrl: require("../assets/tecnologias/opencv.png"),
      needBackground: true,
    },
    {
      name: "OpenGL",
      imgUrl: require("../assets/tecnologias/opengl.png"),
      needBackground: true,
    },
  ];

  const [certificacionesArray, setCertificacionesArray] = useState([
    {
      title: "HarvardX",
      description: "CS50W: CS50's Web Programming with Python and JavaScript",
      imgUrl: require("../assets/certificaciones/harvardx.png"),
      webUrl:
        "https://courses.edx.org/certificates/e49fd6d278dd453980cd8300a814ef1f",
    },
    {
      title: "IBM",
      description: "DA0101EN: Analyzing Data with Python",
      imgUrl: require("../assets/certificaciones/ibm.png"),
      webUrl:
        "https://courses.edx.org/certificates/2cfb833f24044ee289f5eb1b2640fd83",
    },
    {
      title: "AWS",
      description: "Amazon AWS Desarrollo. Curso de AWS programador certificado",
      imgUrl: require("../assets/certificaciones/aws.jpeg"),
      webUrl:
        "https://www.udemy.com/certificate/UC-be1c602c-0655-4c90-9284-068e66e662bc/",
    }
  ]);

  const certificacionesArray_2 = [
    {
      title: "React Native E2E",
      description: "Detox & Cucumber: React Native E2E - Beginner to Advanced",
      imgUrl: require("../assets/certificaciones/detox.png"),
      webUrl:
        "https://www.udemy.com/certificate/UC-a59e835c-78c3-4ee6-807a-fa192b02fd76/",
    },
    {
      title: "Docker",
      description: "Ultimate Docker: guía de cero hasta despliegues",
      imgUrl: require("../assets/certificaciones/docker.jpeg"),
      webUrl:
        "https://www.udemy.com/certificate/UC-793881a0-f8c7-464f-a544-8e04a907bbb5/",
    },
    {
      title: "React-Native",
      description: "React Native: Crea aplicaciones móviles reales iOS y Android",
      imgUrl: require("../assets/certificaciones/react-native.jpg"),
      webUrl:
        "https://www.udemy.com/certificate/UC-497f2e52-1359-4107-865b-ddc48f6b3921/",
    },
  ]
  const [isAdded, setIsAdded] = useState(false);

  const toggleCertificaciones = () => {
    if (isAdded) {
      // Restar los objetos de certificacionesArray_2
      setCertificacionesArray(prevArray =>
        prevArray.filter(
          item => !certificacionesArray_2.some(
            cert => cert.title === item.title && cert.description === item.description
          )
        )
      );
    } else {
      // Sumar los elementos de certificacionesArray_2
      setCertificacionesArray(prevArray => [...prevArray, ...certificacionesArray_2]);
    }
    setIsAdded(!isAdded);
  };


  const IconTech = ({ index, imgUrl, name, needBackground }) => {
    return (
      <Col
        xs={3}
        md={2}
        lg={2}
        className="mb-4 mx-1 d-flex justify-content-center"
      >
        <div className="w-50 my-auto">
          <Image
            src={imgUrl}
            fluid
            className={[
              needBackground === true ? "bg-white" : null,
              "rounded p-2",
            ]}
            style={{ opacity: 0.9 }}
          />
          <h6 className="text-center mt-1">{name}</h6>
        </div>
      </Col>
    );
  };

  return (
    <>
      <section className="skill" id="skills">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="skill-bx wow zoomIn">
                <h2>{t("skillsTitle")}</h2>
                <p>{t("skillsText")}</p>
              </div>
            </div>
          </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
      </section>
      <section className="skill" id="lenguajes">
        <div className="container">
          <TrackVisibility>
            {({ isVisible }) => (
              <div>
                <Tab.Container id="skills-tabs" defaultActiveKey="first">
                  <Nav
                    variant="pills"
                    className="nav-pills mb-5 justify-content-center align-items-center"
                    id="pills-tab"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t("certificaciones")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">{t("tecnologias")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">{t("frameworks")}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content
                    id="slideInUp"
                  >
                    <Tab.Pane eventKey="second">
                      <Row>
                        <Col md={12} lg={1} xl={2} />

                        <Col
                          md={12}
                          lg={10}
                          xl={8}
                          className="d-flex flex-wrap justify-content-between"
                        >
                          {tecnoloigasArray.map((tecnologia, index) => {
                            return <IconTech key={index} {...tecnologia} />;
                          })}
                        </Col>

                        <Col md={11} lg={1} xl={2} />
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        <Col md={12} lg={1} xl={2} />

                        <Col
                          md={12}
                          lg={10}
                          xl={8}
                          className="d-flex flex-wrap justify-content-between"
                        >
                          {frameworksArray.map((framework, index) => {
                            return <IconTech key={index} {...framework} />;
                          })}
                        </Col>

                        <Col md={11} lg={1} xl={2} />
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {certificacionesArray.map((certificacion, index) => {
                          return <ProjectCard key={index} {...certificacion} />;
                        })}

                        {/* Botón "Ver más" */}
                        <Col xs={12} className="text-center mt-4">
                          <button onClick={toggleCertificaciones} className="btn_cv">
                            {isAdded ? t("textVerMenos") : t("textVerMas")}
                          </button>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            )}
          </TrackVisibility>
        </div>
      </section>
    </>
  );
};
