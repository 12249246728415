import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const api = {
  login: async () => {
    try {
      const response = await axios.post(API_URL + "/auth/login", {
        email: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PASSWORD,
      });
      return response.data; // Devuelve los datos de la respuesta de la API
    } catch (error) {
      throw error; // Lanza cualquier error que ocurra durante la solicitud
    }
  },
  logout: async (api_token) => {
    try {
      const response = await axios.get(API_URL + "/auth/logout", {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      return response.data; // Devuelve los datos de la respuesta de la API
    } catch (error) {
      throw error; // Lanza cualquier error que ocurra durante la solicitud
    }
  },
  saveCliente: async (data, api_token) => {
    try {
      const response = await axios.post(API_URL + "/clientes", data, {
        headers: {
          Authorization: `Bearer ${api_token}`,
        },
      });
      return response.data; // Devuelve los datos de la respuesta de la API
    } catch (error) {
      throw error; // Lanza cualquier error que ocurra durante la solicitud
    }
  },
};

export default api;
