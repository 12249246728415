import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useTranslation } from "react-i18next";

export const Banner = () => {
  const { t } = useTranslation();
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(400 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [isVisiblePromt, setIsVisiblePromt] = useState(true);
  const [hasSpaceInText, setHasSpaceInText] = useState(false);
  const toRotate = t("arrayFrasesSkills", { returnObjects: true });
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tiktakPrompt = (times) => {
    return new Promise((resolve, reject) => {
      try {
        if (times < 0) {
          reject("Times could be > 0");
        } else {
          const interval = setInterval(() => {
            setIsVisiblePromt((prevIsVisiblePromt) => !prevIsVisiblePromt);
          }, 500);

          setTimeout(async () => {
            clearInterval(interval);

            setIsVisiblePromt(true);
            resolve({ isFinished: true, interval });
          }, times * 1000);
        }
      } catch (error) {
        reject(error);
        setIsVisiblePromt(true);
      }
    });
  };

  const tick = async () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    updatedText[updatedText.length - 1] === " "
      ? setHasSpaceInText(true)
      : setHasSpaceInText(false);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period * 2 + 350);
      await tiktakPrompt(period / 1000 + 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col md={12} lg={8}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">{t("welcomeMessage")}</span>
                  <h1>
                    {`${t("hello")} Diego`}{" "}
                    <span className="txt-rotate">
                      {text}
                      <span
                        style={hasSpaceInText ? { paddingRight: 25 } : null}
                        className={isVisiblePromt ? "wrap" : ""}
                      />
                    </span>
                  </h1>
                  <p>{`${t("aboutMe")}`}</p>
                  <a className="button" href="#connect">
                    {t("connectButton")} <ArrowRightCircle size={25} />
                  </a>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col md={12} lg={4} className="d-flex align-items-center">
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={`${
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  } mt-md-5 mt-lg-0`}
                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
