import { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { login, logout, saveCliente } from "../reducers/APIReducer"; // Asegúrate de importar las acciones correctamente

export const Contact = () => {
  const { t, i18n } = useTranslation();
  const formInitialDetails = {
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);

  let initBtnText = t("emailBtn1");
  const [buttonText, setButtonText] = useState(
    initBtnText ? initBtnText : "Send"
  );

  /// REDUX
  const dispatch = useDispatch();

  useEffect(() => {
    let initBtnText = t("emailBtn1");
    setButtonText(initBtnText ? initBtnText : "Send");
  }, [t("emailBtn1")]);

  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let sendingText = t("emailBtn2");

    setButtonText(sendingText ? sendingText : "Sending...");
    await submitNewCliente(formDetails);

    let restoreTXT = t("emailBtn1");
    setButtonText(restoreTXT ? restoreTXT : "Send...");
    setButtonText(restoreTXT ? restoreTXT : "Send");
  };

  const submitNewCliente = async (body) => {
    const isLogedIn = await handleLogin();

    if (isLogedIn) {
      const isSavedCliente = await handleSaveCliente(body);

      if (isSavedCliente) {
        setStatus({ succes: true, message: "Message sent successfully" });
        setFormDetails(formInitialDetails);

        setTimeout(() => {
          setStatus({});
        }, 2000);
      }

      await handleLogout();
    }
  };

  const handleLogin = async () => {
    return dispatch(login());
  };

  const handleLogout = async () => {
    return dispatch(logout());
  };

  const handleSaveCliente = async (data) => {
    data = { ...data, languaje: i18n.language };
    return dispatch(saveCliente(data));
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>{t("emailText")}</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          required
                          type="text"
                          value={formDetails.name}
                          placeholder={t("formName")}
                          onChange={(e) => onFormUpdate("name", e.target.value)}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          required
                          type="text"
                          value={formDetails.lastName}
                          placeholder={t("formLastName")}
                          onChange={(e) =>
                            onFormUpdate("lastName", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          required
                          type="email"
                          value={formDetails.email}
                          placeholder={t("formEmail")}
                          onChange={(e) =>
                            onFormUpdate("email", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          required
                          type="tel"
                          value={formDetails.phone}
                          placeholder={t("formPhone")}
                          onChange={(e) =>
                            onFormUpdate("phone", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea
                          required
                          rows="6"
                          value={formDetails.message}
                          placeholder={t("formMessage")}
                          onChange={(e) =>
                            onFormUpdate("message", e.target.value)
                          }
                        ></textarea>
                        <button style={{ borderRadius: 10 }} type="submit">
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                      {status.message && (
                        <Col>
                          <Alert className="fade" variant="success">
                            {status.message}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
