// App.js
import React from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";

/// REDUX
import { Provider } from "react-redux";
import store from "./store"; // Importa el store que definiste

/// i18next (LIBRERIA PARA LOS IDIOMAS)
import { initReactI18next, I18nextProvider } from "react-i18next";
import i18n from "i18next";

// Importa tus archivos JSON de traducción
import translationEN from "./locales/en.json";
import translationES from "./locales/es.json";

// Configuración de i18next
i18n
  .use(initReactI18next) // Inicializa react-i18next
  .init({
    lng: "es", // Idioma predeterminado
    fallbackLng: "en", // Idioma de respaldo si no se encuentra la traducción
    resources: {
      en: {
        translation: translationEN, // Traducciones en inglés
      },
      es: {
        translation: translationES, // Traducciones en español
      },
    },
    react: {
      useSuspense: true, // Si estás usando React 16.8 o posterior, puedes ponerlo en true
    },
  });

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <NavBar />
          <Banner />
          <Skills />
          <Projects />
          <Contact />
          <Footer />
        </I18nextProvider>
      </Provider>
    </div>
  );
}

export default App;
