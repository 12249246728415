import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/zamora.png";
import Social from "./Social";
import { Newsletter } from "./Newsletter";

export const Footer = () => {
  const date = new Date()

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Newsletter />
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 1 }}
            className="text-center text-md-left mb-4 mb-md-0"
          >
            <img src={logo} alt="Logo" style={{ width: "40%" }} />
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: 2 }}
            className="text-center text-md-left"
          >
            <Social />
            <p>Copyright {date.getFullYear()}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
