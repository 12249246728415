import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, webUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <a href={webUrl} target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
        <div className="proj-imgbx">
          <img src={imgUrl} />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
            <br />
            {webUrl && (
              <a
                href={webUrl}
                className="btn btn-sm ps-1 pe-1 pt-0 pb-0 mt-2 btn-link bg-white"
                target="_blank"
              >
                View <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            )}
          </div>
        </div>
      </a>
    </Col>
  );
};
