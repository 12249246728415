import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Form, InputGroup } from "react-bootstrap";
import logo from "../assets/img/zamora.png";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import Social from "./Social";

export const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const changeLanguage = (event) => {
    if (event.target.value) {
      const newLanguage = event.target.value;
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <Router>
      <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="zamora-logo" />
          </Navbar.Brand>
          <Navbar.Brand>
            <InputGroup data-bs-theme="dark" size="sm">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faLanguage} />
              </InputGroup.Text>
              <Form.Select
                data-bs-theme="dark"
                size="sm"
                onChange={changeLanguage}
              >
                <option value="es">Español</option>
                <option value="en">English</option>
              </Form.Select>
            </InputGroup>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                {t("homeButton")}
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("skills")}
              >
                {t("skillsButton")}
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("projects")}
              >
                {t("projectsButton")}
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <Social />
              <HashLink to="#connect">
                <button className="vvd">
                  <span>{t("connectButton")}</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
