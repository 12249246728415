// APIReducer.js
import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";

const initialState = {
  api_token: undefined,
  loading: false, // Agrega un estado para el indicador de carga
};

const APIReducer = createSlice({
  name: "API",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.api_token = action.payload;
    },
    loginFailure: (state) => {
      state.loading = false;
    },
    logoutStart: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.api_token = undefined;
    },
    logoutFailure: (state) => {
      state.loading = false;
    },
    saveClienteStart: (state) => {
      state.loading = true;
    },
    saveClienteSuccess: (state) => {
      state.loading = false;
    },
    saveClienteFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutStart,
  logoutSuccess,
  logoutFailure,
  saveClienteStart,
  saveClienteSuccess,
  saveClienteFailure,
} = APIReducer.actions;

// Acción asíncrona para login
export const login = () => async (dispatch) => {
  dispatch(loginStart());
  try {
    // Realiza una solicitud asincrónica, por ejemplo, una llamada a una API
    const response = await api.login();
    if (response.status === true) dispatch(loginSuccess(response.api_token));
    else dispatch(loginFailure());
    return true;
  } catch (error) {
    dispatch(loginFailure());
    return false;
  }
};

// Acción asíncrona para logout
export const logout = (data) => async (dispatch, getState) => {
  const { api_token } = getState().API; // Accede al estado api_token
  dispatch(logoutStart());
  try {
    // Realiza una solicitud asincrónica, por ejemplo, una llamada a una API
    await api.logout(api_token);
    dispatch(logoutSuccess());
    return true;
  } catch (error) {
    dispatch(logoutFailure());
    return false;
  }
};

// Acción asíncrona para send_cliente
export const saveCliente = (data) => async (dispatch, getState) => {
  const { api_token } = getState().API; // Accede al estado api_token
  dispatch(saveClienteStart());
  try {
    // Realiza una solicitud asincrónica, por ejemplo, una llamada a una API
    await api.saveCliente(data, api_token);
    dispatch(saveClienteSuccess());
    return true;
  } catch (error) {
    dispatch(saveClienteFailure());
    return false;
  }
};

export default APIReducer.reducer;
