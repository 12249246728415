// reducers.js
import { combineReducers } from "redux";
import APIReducer from "./APIReducer"; // Crea este archivo para cada reducer que necesites

const rootReducer = combineReducers({
  API: APIReducer,
  // Otros reducers aquí...
});

export default rootReducer;
