// store.js
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "../reducers";
import thunk from "redux-thunk"; // Importa Redux Thunk

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), thunk], // Agrega Redux Thunk al middleware
});

export default store;
