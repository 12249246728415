import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useTranslation } from "react-i18next";
import Social from "./Social";
import YouTubeGrid from "./YouTubeGrid";

const imagenes = {
  amigo: require("../assets/proyectos/soporte_tecnico.jpg"),
  lilab: require("../assets/proyectos/lab.jpg"),
  youtube: require("../assets/proyectos/youtube.png"),
};

export const Projects = () => {
  const { t } = useTranslation();
  const projects = t("projectsArray", { returnObjects: true });

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2>{t("projectsTitle")}</h2>
                  <p>{t("projectsText")}</p>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: 50
                    }}
                  >
                    <Social isVisibleLinkedin={false} />
                  </div>

                  <YouTubeGrid text={t("textVerMas")} />

                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
