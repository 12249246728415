import React from "react";
import youtube from "../assets/img/youtube.svg";
import github from "../assets/img/github.svg";
import linkedin from "../assets/img/linkedin.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Social({
  isVisibleYoutube = true,
  isVisibleGit = true,
  isVisibleLinkedin = true,
}) {
  const tooltipYT = (
    <Tooltip id="tooltip">
      <strong>Youtube</strong>
    </Tooltip>
  );

  const tooltipGit = (
    <Tooltip id="tooltip">
      <strong>GitHub</strong>
    </Tooltip>
  );

  const tooltipLinkedIn = (
    <Tooltip id="tooltip">
      <strong>LinkedIn</strong>
    </Tooltip>
  );

  return (
    <div className="social-icon">
      {isVisibleYoutube && (
        <OverlayTrigger placement="bottom" overlay={tooltipYT}>
          <a
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLGE1nAVn_sghnLQUj9pKO1knYOKa6HUtn"
            className="mb-sm-0 mb-md-2 mb-xl-0"
          >
            <img src={youtube} alt="Youtube" style={{ width: 20 }} />
          </a>
        </OverlayTrigger>
      )}

      {isVisibleGit && (
        <OverlayTrigger placement="bottom" overlay={tooltipGit}>
          <a
            target="_blank"
            href="https://github.com/DiegoZamora33"
            className="mb-sm-0 mb-md-2 mb-xl-0"
          >
            <img src={github} alt="Github" style={{ width: 20 }} />
          </a>
        </OverlayTrigger>
      )}

      {isVisibleLinkedin && (
        <OverlayTrigger placement="bottom" overlay={tooltipLinkedIn}>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/diego-zamora33/"
            className="mb-sm-0 mb-md-2 mb-xl-0"
          >
            <img src={linkedin} alt="LinkedIn" style={{ width: 18 }} />
          </a>
        </OverlayTrigger>
      )}
    </div>
  );
}

export default Social;
